<template>
  <div id="planManagement" :style="{ width: tableWidth + ' !important' }">
    <filter-pane ref="filterPane">
      <template v-slot:filter>
        <div class="searchForm">
          <div>
            <el-input v-model="dataForm.keyword" placeholder="输入方案或者编号" class="searchInput" @input="searchForm"></el-input>
            <my-select
              :isLoadMore="false"
              :options="optionGroup.sponsorOption"
              v-model="dataForm.sponsorIdEq"
              @input="searchForm"
              :placeholder="'请选择申办方'"
              style="width: 230px"
            ></my-select>
          </div>
          <div>
            <el-button type="default" v-if="authGroup.export" size="mini" class="addButton" @click="dialogExportVisible = true">导出</el-button>
            <el-button v-if="authGroup.create" type="primary" size="mini" class="addButton" @click="serviceItem(0)">添加方案</el-button>
          </div>
        </div>
      </template>
    </filter-pane>
    <EditServiceItemTable
      :reportType="'plan'"
      :authGroup="authGroup"
      :applyComp="'plan'"
      :tableLoading.sync="tableLoading"
      :serviceTableData="serviceTableData"
      :chooseInputArr="chooseInputArr"
      :tableHeight="tableHeight"
      @deleteData="deleteData"
      :editDiago="true"
      @editServiceItem="serviceItem"
      :requiredIndex="requiredIndex"
      :saveKey.sync="saveKey"
      :page="dataForm.page"
      :pagesize="dataForm.pagesize"
      @pageChange="pageChange"
      :count="count"
    ></EditServiceItemTable>

    <SimpleDialog
      class="PlanDialog"
      :title="editId ? '编辑方案' : '添加方案'"
      :visible.sync="dialogVisible"
      width="522px"
      :confirmBtnText="confirmBtnText"
      :confirmBtnLoading="dialogLoading"
      showConfirm
      showCancel
      @onCancel="dialogClose"
      @onSubmit="DialogSubmit"
      :before-close="dialogClose"
    >
      <el-form :model="DialogForm" ref="DialogForm" class="DialogForm" :rules="dialogRules" :label-position="labelPosition" label-width="150px">
        <el-form-item prop="code" label="方案编号">
          <el-input v-model="DialogForm.code" placeholder="请输入" @change="validateReset('code')"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="方案名称">
          <el-input v-model="DialogForm.name" placeholder="请输入" @change="validateReset('name')"></el-input>
        </el-form-item>
        <el-form-item prop="managers" label="负责人">
          <memberSelect
            :projectUpdate="true"
            :memberSelected="DialogForm.managers"
            :allUser="optionGroup.allUser"
            @changeMemberSelected="changeMemberSelected"
          />
        </el-form-item>
        <el-form-item prop="sponsorId" label="归属申办方">
          <my-select :options="optionGroup.sponsorOption" v-model="DialogForm.sponsorId" placeholder="请输入或者下拉选择"></my-select>
        </el-form-item>
        <el-form-item prop="phaseId" label="临床分期">
          <my-select :options="optionGroup.phaseOption" v-model="DialogForm.phaseId" placeholder="请输入或者下拉选择"></my-select>
        </el-form-item>
        <el-form-item prop="indicationId" label="适应症">
          <my-select :options="optionGroup.indicationOption" v-model="DialogForm.indicationId" placeholder="请输入或者下拉选择"></my-select>
        </el-form-item>
        <el-form-item prop="therapeuticId" label="治疗领域">
          <my-select :options="optionGroup.therapeuticOption" v-model="DialogForm.therapeuticId" placeholder="请输入或者下拉选择"></my-select>
        </el-form-item>
        <el-form-item prop="destPersonSize" label="入组目标人数">
          <el-input v-model.number="DialogForm.destPersonSize" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item prop="siteCountry" label="site country">
          <el-autocomplete
            class="inline-input"
            v-model="DialogForm.siteCountry"
            :fetch-suggestions="querySearch"
            placeholder="请输入"
          ></el-autocomplete>
        </el-form-item>
      </el-form>
    </SimpleDialog>
    <SimpleDialog
      class="exportDialog"
      :showConfirm="true"
      :showCancel="true"
      :confirmBtnLoading="confirmBtnLoading"
      confirmBtnText="导出"
      @onCancel="exportDialogClose"
      @onSubmit="exportDialogSubmit"
      title="提示"
      :visible.sync="dialogExportVisible"
      width="480px"
    >
      <div class="sponsorContainer">
        <span class="descName">申办方</span>
        <memberSelect
          class="sponsorSelect"
          specifiedPlaceholder="请选择申办方"
          :memberSelected="sponsorIdIn"
          :projectUpdate="true"
          :allUser="optionGroup.sponsorOption"
          @changeMemberSelected="exportMemberSelected"
        />
      </div>
    </SimpleDialog>
  </div>
</template>

<script>
import SimpleDialog from '@/components/common/SimpleDialog';
import MySelect from '@/components/common/MySelect';

import resourceManagement from '@/api/modules/resourceManagement.api.js';
import projectManagementApi from '@/api/modules/projectManagement.api.js';
import resourceManagementApi from '@/api/modules/resourceManagement.api';

import FilterPane from '@/views/project-data/components/FilterPane';
import EditServiceItemTable from './components/EditServiceItemTable';
import memberSelect from '@/components/memberSelect/index.vue';

import { mixinsResource } from '@/mixins/resourceData';
import { debounce } from '@/utils/debounce';
import responseCheck from '@/utils/saveAs';

export default {
  components: {
    EditServiceItemTable,
    FilterPane,
    memberSelect,
    SimpleDialog,
    MySelect,
  },
  name: 'PlanManagement',
  mixins: [mixinsResource],
  created() {
    this.authGroup.create = this.$store.state.app.userConfig.permissionsKeys?.PlanManagementCreate;
    this.authGroup.export = this.$store.state.app.userConfig.permissionsKeys?.PlanManagementExport;
    this.authGroup.update = this.$store.state.app.userConfig.permissionsKeys?.PlanManagementUpdate;
    this.authGroup.del = this.$store.state.app.userConfig.permissionsKeys?.PlanManagementDelete;
    this.authGroup.read = this.$store.state.app.userConfig.permissionsKeys?.PlanManagementRead;
    this.refreshData();
    let sponsorData = resourceManagement.sponsorData({ page: 1, pagesize: 999999 });
    sponsorData.then(res => {
      this.optionGroup.sponsorOption = res[0].sponsors;
    });
    this.getSearchByUserName();
  },
  data() {
    const validateCode = (rule, value, callback) => {
      if (this.codeError) {
        callback(new Error('编号已被占用'));
      } else if (!value) {
        callback(new Error('请输入编号名称'));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (this.nameError) {
        callback(new Error('名称已被占用'));
      } else if (!value) {
        callback(new Error('请输入方案名称'));
      } else {
        callback();
      }
    };
    return {
      exportMemberSelectedName: [],
      confirmBtnLoading: false,
      sponsorIdIn: [],
      dialogExportVisible: false,
      authGroup: {
        export: false,
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      optionGroup: { phaseOption: [], indicationOption: [], therapeuticOption: [], sponsorOption: [], allUser: [], siteCountryOption: [] },
      confirmBtnText: '确定',
      DialogForm: {
        code: '',
        managers: [],
        name: '',
        sponsorId: '',
        phaseId: '',
        indicationId: '',
        therapeuticId: '',
        destPersonSize: undefined,
        siteCountry: '',
      },
      labelPosition: 'right',
      dialogLoading: false,
      dialogVisible: false,
      codeError: false,
      nameError: false,
      showMemberSelectedName: [],
      dialogRules: {
        code: [{ required: true, type: 'string', validator: validateCode, trigger: 'blur' }],
        name: [{ required: true, type: 'string', validator: validateName, trigger: 'blur' }],
        phaseId: [{ type: 'string', required: true, message: '请输入或者下拉选择', trigger: 'change' }],
        sponsorId: [{ type: 'string', required: true, message: '请输入或者下拉选择', trigger: 'change' }],
        indicationId: [{ type: 'string', required: true, message: '请输入或者下拉选择', trigger: 'change' }],
        therapeuticId: [{ type: 'string', required: true, message: '请输入或者下拉选择', trigger: 'change' }],
        managers: [{ type: 'array', required: true, message: '请选择', trigger: 'change' }],

        destPersonSize: [{ required: true, message: '请输入' }],
      },

      requiredIndex: [],
      serviceTableData: [],
      count: 0,
      chooseInputArr: [
        {
          prop: 'code',
        },
        {
          prop: 'name',
          width: 200,
        },
        {
          prop: 'sponsorName',
        },
        {
          prop: 'phaseName',
        },
        {
          prop: 'indicationName',
          width: 240,
        },
        {
          prop: 'therapeuticName',
          width: 340,
        },
        {
          prop: 'destPersonSize',
          align: 'center',
          width: 120,
        },
        {
          prop: 'siteCountry',
        },
      ],
      choosedContractItem: {}, //当前选择的事项
      shouldShowErrimg: false,
      tableLoading: false,
      dataForm: {
        page: 1,
        pagesize: 20,
        keyword: '',
        sponsorIdEq: '',
      },
      editId: '',
      searchKey: false,
      saveKey: false,
      recordData: [],

      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
    };
  },

  methods: {
    querySearch(queryString, cb) {
      let customerEqs = [...this.optionGroup.siteCountryOption];
      customerEqs = customerEqs.map(item => {
        return { value: item, label: item };
      });
      const results = queryString ? customerEqs.filter(this.createFilter(queryString)) : customerEqs;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return customerEq => {
        return customerEq.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    exportDialogClose() {
      this.sponsorIdIn = [];
      this.dialogExportVisible = false;
    },
    async exportDialogSubmit() {
      this.confirmBtnLoading = true;
      const res = await resourceManagementApi.exportStatement({ sponsorIdIn: this.sponsorIdIn });
      this.confirmBtnLoading = false;
      responseCheck(res[2]);
      this.exportDialogClose();
    },
    exportMemberSelected(vals, ids) {
      this.exportMemberSelectedName = [...vals];
      this.sponsorIdIn = [...ids];
    },
    changeMemberSelected(vals, ids) {
      this.showMemberSelectedName = [...vals];
      this.DialogForm.managers = [];
      const memberIds = [...ids];
      memberIds.forEach(item => {
        this.DialogForm.managers.push(item);
      });
    },
    pageChange(type, vals) {
      if (type === 'size') {
        this.dataForm.pagesize = vals;
      } else {
        this.dataForm.page = vals;
      }
      this.refreshData();
    },
    async getSearchByUserName() {
      let [data] = await projectManagementApi.getSearchUserName({ page: 1, pagesize: 99999 });
      if (!data) return;
      this.optionGroup.allUser = [...data.user];
    },
    getOptions() {
      Promise.all([
        resourceManagement.phasesData({ page: 1, pagesize: 99999, name: this.DialogForm.phaseId }),
        resourceManagement.indicationsData({ page: 1, pagesize: 99999, name: this.DialogForm.indicationId }),
        resourceManagement.therapeuticsData({ page: 1, pagesize: 99999, name: this.DialogForm.therapeuticId }),
        resourceManagement.sponsorData({ page: 1, pagesize: 99999, name: this.DialogForm.sponsorId }),
        resourceManagement.getSiteCountries(),
      ]).then(res => {
        this.optionGroup.phaseOption = res[0][0].phases;
        this.optionGroup.indicationOption = res[1][0].indications;
        this.optionGroup.therapeuticOption = res[2][0].therapeutics;
        this.optionGroup.sponsorOption = res[3][0].sponsors;
        this.optionGroup.siteCountryOption = res[4][0].siteCountries;
      });
    },
    async deleteData(id) {
      try {
        await resourceManagement.deleteProtocolsData(id);
        this.refreshData();
      } catch (err) {
        this.$message.error('删除失败！');
        return;
      }
    },
    async refreshData() {
      if (!this.authGroup.read) {
        return;
      }
      this.tableLoading = true;
      resourceManagement
        .protocolsData(this.dataForm)
        .then(res => {
          if (!res[0].protocols || !res[0].protocols.length) {
            this.tableLoading = false;
            this.serviceTableData = [];
            this.tableLoading = false;
            this.shouldShowErrimg = true;
            this.searchKey = false;

            return;
          }
          const result = res[0].protocols;
          /*           this.countProjSummary = result.length; */
          this.serviceTableData = [...result];

          this.searchKey = false;
          this.tableLoading = false;
          this.shouldShowErrimg = false;
          this.count = res[0].count ? res[0].count : 0;
          this.addSum = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //添加服务项
    async serviceItem(val) {
      if (val) {
        this.DialogForm = Object.assign({}, val);
        this.editId = val.id;
      } else {
        this.DialogForm = {
          code: '',
          name: '',
          sponsorId: '',
          phaseId: '',
          indicationId: '',
          managers: [],
          therapeuticId: '',
          destPersonSize: undefined,
          siteCountry: '',
        };
      }

      await this.getOptions();
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.DialogForm.clearValidate();
      });
    },

    searchForm() {
      if (this.searchKey) {
        return;
      }
      this.searchKey = true;
      const searchSubmit = debounce(this.refreshData, 800);
      this.dataForm.page = 1;
      this.dataForm.pagesize = 20;
      this.count = 0;
      searchSubmit();
    },
    validateReset(val) {
      val === 'code' ? (this.codeError = false) : val === 'name' ? (this.nameError = false) : null;
    },
    async DialogSubmit() {
      this.$refs.DialogForm.validate(async valid => {
        if (!valid) {
          return;
        }
        this.confirmBtnText = '提交中';
        this.dialogLoading = true;

        const res = this.editId
          ? await resourceManagement.editProtocolsData(this.editId, this.DialogForm)
          : await resourceManagement.addProtocolsData(this.DialogForm);
        this.dialogLoading = false;
        this.confirmBtnText = '确定';
        if (!res[1].code) {
          this.dialogClose();
          await this.refreshData();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
          this.editId = '';
          this.$nextTick(() => {
            this.$refs.DialogForm.clearValidate();
          });
        } else if (res[1].metadata) {
          const errData = res[1].metadata;
          errData.code ? (this.codeError = true) : null;
          errData.name ? (this.nameError = true) : null;
          this.editId ? null : this.$refs.DialogForm.validate();
        }
      });
    },
    dialogClose() {
      this.dialogVisible = false;
      this.editId = '';
      this.$refs.DialogForm.resetFields();
      this.DialogForm = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
@import '@/styles/element-ui.scss';

#planManagement {
  @include resourceStyle;
  padding: 14px 0;
  padding-bottom: 0;
  border-radius: 2px;
  margin: 0 16px;
  margin-right: 24px;
  margin-top: 0;
  overflow-y: hidden;

  ::v-deep.el-table {
    color: #515a6e;
  }

  ::v-deep .el-dialog__header {
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    // justify-content: center;
  }

  .DialogForm {
    padding-top: 32px;

    .el-form-item {
      margin-bottom: 32px;
      margin-right: 32px;
    }

    .mySelect {
      width: 100%;
    }
  }
}

.exportDialog {
  ::v-deep .el-dialog__header {
    justify-content: flex-start !important;
    padding: 6px 16px;

    .el-dialog__headerbtn {
      display: none;
    }

    border-bottom: none !important;
  }
  ::v-deep .el-dialog__body {
    height: 150px;
  }
  ::v-deep .footer {
    display: flex;
    justify-content: flex-end;

    .el-button {
      width: 57px !important;
      height: 28px !important;
      font-size: 14px;
      display: flex;
      margin: 0;
      justify-content: center;
      align-items: center;
    }
  }

  .sponsorContainer {
    display: flex;
    align-items: center;
    // padding-left: 32px;
    padding: 15px 0 28px 32px;

    .sponsorSelect {
      width: 328px;
    }

    .descName {
      padding-right: 12px;
    }
  }
}
</style>
